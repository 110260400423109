import React from "react";
import {Auth} from "aws-amplify";

import {CCard, CRow, CCol, CButton, CCardBody, CCardTitle, CCardText} from '@coreui/react-pro';

function FederatedSignIn(props) {
  return (
    <CRow>

      <CCol>
        <CCard >
          <CCardBody>
            <CRow>

              <CCol sm={6}>

                <CButton
                  block
                  //variant="success"
                  onClick={() => {
                    Auth.federatedSignIn({provider: props.federatedIdName}).then(cred => {
                        // If success, you will get the AWS credentials
                        console.log("Cred: " + cred);
                    }).then(user => {
                        // If success, the user object you passed in Auth.federatedSignIn
                        console.log("User: " + user);
                    }).catch(e => {
                        console.log("ERROR: " + e)
                    })
                }
                  }
                >
                  Sign In with Azure
                </CButton>
              </CCol>

            </CRow>
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
  );
}

export default FederatedSignIn;
import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

import { Amplify, Auth, Hub } from 'aws-amplify'
import { ThemeProvider, Authenticator, Button, Flex, useAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from "./aws-exports";
import components from "./AuthenticatorComponents";
import background from './assets/images/background-1.jpg';
import FederatedSignIn from './FedereatedSignIn';
import { useTranslation } from 'react-i18next';
import  './i18n';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('de');

// Amplify.configure(awsExports);

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_XSoEo2AdU',
    userPoolWebClientId: '3k4uatljr2pnhi5jevru9rmcu1',
    mandatorySignIn: true,
    oauth: {
      domain: 'auth.workbench.reload-apps.com',
      scope: ['email', 'openid'],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      responseType: 'code',
    },
  },
});


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const myCustomTheme = {
  name: 'reload-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#024080' },
        // ...
      },
      font: {
        primary: { value: 'red' },
      },
    },
    components: {
      card: {
        backgroundColor: { value: '{colors.background.primary}' },
        outlined: {
          borderColor: { value: '{colors.black}' },
        },
      },
      heading: {
        color: { value: '{colors.brand.secondary[80]}' },
      },
      text: {
        color: { value: '{colors.brand.primary[80]}' },
      },
    },    
  },
};

const handleAuthStateChange = (state) => {
  if (state === 'signedIn') {
    // User has signed in
    // Do something here, like redirecting to a protected route
    console.log('User has signed in');
  }
};

function App( { signOut, user } ) {  

  const { t } = useTranslation();



  const formFields = {
    signUp: {
      username: {
        placeholder: t('Signin.UsernameEnter'),
        isRequired: true,
        label: t('Signin.Username'),
        labelHidden: false,
      },
      email: {
        placeholder: t('Signin.EmailEnter'),
        isRequired: true,
        label: 'Email:',
        labelHidden: false,
      },
      password: {
        placeholder: t('Signin.PasswordEnter'),
        isRequired: true,
        label: t('Signin.Password'),
        labelHidden: false,
      },
    },
    signIn: {
      username: {
        placeholder: t('Signin.UsernameEnter'),
        isRequired: true,
        label: t('Signin.Username'),
        labelHidden: false,
      },
      password: {
        placeholder: t('Signin.PasswordEnter'),
        isRequired: true,
        label: t('Signin.Password'),
        labelHidden: false,
      },
    }
  };

  useEffect(() => {
    console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
    console.log("process.env.REACT_APP_VERSION: ", process.env.REACT_APP_VERSION);
    console.log("process.env.REACT_APP_ENV: ", process.env.REACT_APP_ENV);



    Hub.listen('auth', ({ payload: { event, data } }) => {
      //console.log("event = " + event)

      switch (event) {
        case 'signIn':
          // setUser(data.signInUserSession.idToken.payload)
          // setUserLoggedIn(true)
          break;
        case 'cognitoHostedUI':
          break;
        case 'signOut':
          // setUserLoggedIn(false)
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        default:
      }
    });


  }, [])

    return (
      <div className="App" slot="sign-in" style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        height: '100vh'
        
      }}>
      <ThemeProvider>
      
      <Authenticator 
        components = { components } 
        formFields = { formFields }
        //hideDefault={true} 
        federated={ { provider: 'rhr-azure-aad' } }
        onStateChange={handleAuthStateChange}
      > 

      {({ signOut, user }) => (
        
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
      )}

      </Authenticator>



      
      
            
       
      </ThemeProvider>

      
      </div>
      

  )
    }
  
export default App;
/*
export default withAuthenticator(App, {
  components: {
    Header,
    
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },   
    Footer,
    
  },
  theme: { myCustomTheme }
});

*/
